import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import * as Rellax from 'rellax';

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styles: [`
    ngb-progressbar {
        margin-top: 5rem;
    }
    .ng-invalid { color: red; border: 1px solid red; }
    .errors {color: red}
    `]
})

export class ComponentsComponent implements OnInit, OnDestroy {

    private fragment: string;

    data: Date = new Date();

    customRange1 = 0;
    customRange2 = 0;
    IsTrueTasa1 = false;
    IsTrueTasa2 = false;
    IsTrueTasa3 = false;
    Tasa1 = 7.5;
    Tasa2 = 10.5;
    Tasa3 = 14;
    TasaSelect = 0;
    Total = 0;
    factorxdia = 0
    tiempo = 0
    kapital = 0 
    ganancia = 0
    interes = 0
    plazo = 0
    tasaperiodo = 0

    page = 4;
    page1 = 5;
    page2 = 3;
    enabled = false;
    disabled = true;
    focus;
    focus1;
    focus2;

    display = false;


    date: { year: number, month: number };
    model: NgbDateStruct;

    public isCollapsed = true;
    public isCollapsed1 = true;
    public isCollapsed2 = true;

    state_icon_primary = true;

    constructor(private route: ActivatedRoute, renderer: Renderer2, config: NgbAccordionConfig) {
        config.closeOthers = true;
        config.type = 'info';
    }
    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: { month: number }) {
        return date.month !== current.month;
    }

    ngOnInit() {
        const rellaxHeader = new Rellax('.rellax-header');

        const navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('index-page');

        this.route.fragment.subscribe(fragment => {
            this.fragment = fragment;
          });

    }

    AfterViewInit(): void {
        try {
          document.querySelector('#' + this.fragment).scrollIntoView();
        } catch (e) {}
      }
    Tasa1Func() {
        this.TasaSelect = 0;
        this.Total = 0;
        this.factorxdia = 0
        this.tiempo = 0
        this.kapital = 0 
        this.ganancia = 0
        this.interes = 0
        this.plazo = 0
        this.tasaperiodo = 0
        this.TasaSelect = this.Tasa1;
        this.plazo = this.customRange2;
        this.kapital = this.customRange1;
        this.factorxdia = this.Tasa1/365;
        this.tiempo = this.plazo*30;
        this.tasaperiodo = this.tiempo*this.factorxdia;
        this.interes = this.kapital*this.tasaperiodo;
        this.Total = this.kapital+this.interes;

    }

    Tasa2Func() {
        this.TasaSelect = this.Tasa2;
        const Ganancia = (this.customRange1 / this.customRange2) * (this.Tasa2/100)
        this.Total = Ganancia + this.customRange1;
    }

    Tasa3Func() {
        this.TasaSelect = this.Tasa3;
        const Ganancia = (this.customRange1 / this.customRange2) * (this.Tasa3/100)
        this.Total = Ganancia + this.customRange1;
    }

    ngOnDestroy() {
        const navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('index-page');
    }
    
}
